import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '/src/components/layout'
import { StaticHero } from '/src/components/common'

const IndexPage = () => {
  return (
    <Layout hero={<StaticHero title="Mills">
      <StaticImage 
        src="../../images/osullivans-2.png" 
        alt="Hero image"
        width={1420}
        height={250}
        placeholder="blurred"
        layout="fixed"
        transformOptions={{
          cropFocus: 'center'
        }} />
    </StaticHero>} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <Row className="d-flex align-items-center">
              <Col md={3}>
                <div className="title-container p-3">
                  <Link to="/mills/osullivan" className="mb-2">
                    <h2 className="title">O'Sullivan Beach</h2>
                  </Link>
                </div>
              </Col>
              <Col md={9}>
                <Row className="mt-3">
                  <Col sm={12}>
                    <div className="content info mb-4">
                      <p>The Orrcon Steel Manufacturing O'Sullivan Beach Mill is a prominent facility located in the southern suburbs of Adelaide, South Australia. This state-of-the-art mill is an integral part of Orrcon Steel, an Australian company that specializes in the production of high-quality steel products. Since its inception, the O'Sullivan Beach Mill has played a pivotal role in Australia's steel manufacturing industry, significantly contributing to the nation's economic growth and infrastructure development. With a strong commitment to sustainability and innovation, the mill has established itself as a leader in producing high-quality steel products for diverse applications.</p>
                    </div>
                    <Link to="/mills/osullivan" className="mb-2 button primary">Read More</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <Row className="d-flex align-items-center">
              <Col md={3}>
                <div className="title-container p-3">
                  <Link to="/mills/salisbury" className="mb-2">
                    <h2 className="title">Salisbury</h2>
                  </Link>
                </div>
              </Col>
              <Col md={9}>
                <Row className="mt-3">
                  <Col sm={12}>
                    <div className="content info mb-4">
                      <p>The Salisbury facility is equipped with modern technology and state-of-the-art equipment, including a tube mill, slitting lines, and advanced quality control systems, to ensure the consistency and quality of the products produced. The facility employs around 160 people directly and contributes to the employment of many more in the region.</p>
                    </div>
                    <Link to="/mills/salisbury" className="mb-2 button primary">Read More</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <Row className="d-flex align-items-center">
              <Col md={3}>
                <div className="title-container p-3">
                  <Link to="/mills/unanderra" className="mb-2">
                    <h2 className="title">Unanderra</h2>
                  </Link>
                </div>
              </Col>
              <Col md={9}>
                <Row className="mt-3">
                  <Col sm={12}>
                    <div className="content info mb-4">
                      <p>Orrcon Steel is excited by the commissioning of our state of the art Unanderra mill prior to end of June 2024. This cutting-edge facility represents a significant milestone in our commitment to innovation and excellence in steel manufacturing. The Unanderra mill will be instrumental in producing our renowned StrucTube branded product, catering to the diverse needs of our customers.</p>
                    </div>
                    <Link to="/mills/unanderra" className="mb-2 button primary">Read More</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Mills | Orrcon Steel</title>
